import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "context/authenticate";
import CommonLayout from "../../containers/PageAddListing/CommonLayout";
import Spinner from "components/Spinner/Spinner";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { getPureArray, safeParse } from "utils/util";
import { CartCacheLocalStorageKey } from "utils/constant";
import CartSkuItems from "./components/CartSkuItems";
import { commonAxiosPostRequest } from "utils/request";

export interface CartProps { }

const Cart: FC<CartProps> = () => {
	const { configInfo } = useContext(AuthContext);
	const { t } = useTranslation();
	const history = useHistory();
	// 购物车商品列表
	const [cartSkuList, setCartSkuList] = useState<any[]>([]);
	const [transcationTotalAmount, setTranscationTotalAmount] = useState<number>(0);
	// 页面加载状态：0/1/2
	const [loadingState, setLoadingState] = useState<0 | 1 | 2>(0);

	// 初始化获取缓存中的购物车商品sku列表
	const handleGetCartSkuList = async () => {
		loadingState === 0 && setLoadingState(1);
		let cachedCartSkuList: any[] = safeParse(window.localStorage.getItem(CartCacheLocalStorageKey), []);
		cachedCartSkuList = cachedCartSkuList.map(item => ({
			...item,
			skuList: item.skuList.filter((sku: any) => sku.quantity > 0),
		})).filter(item => item.skuList.length > 0);
		const [error, response] = await commonAxiosPostRequest<{ list: any[], transcationTotalAmount: number, freeShippingOrderAmount: number }, {}>('/yogurtrain/api/app/product/cart', {
			cartSkuList: cachedCartSkuList.map(item => ({
				productId: item.productId,
				skuList: item.skuList.map((sku: any) => ({ id: sku.id, quantity: sku.quantity }))
			}))
		});
		const list = getPureArray<any>(response?.list);
		const total = response?.transcationTotalAmount || 0;
		window.localStorage.setItem(CartCacheLocalStorageKey, JSON.stringify(list));
		loadingState === 0 && setLoadingState(2);
		setCartSkuList(list);
		setTranscationTotalAmount(total);
	}

	const onBuyButtonClick = () => {
		history.push("/checkout");
	}

	useEffect(() => {
		handleGetCartSkuList();
	}, []);

	if (loadingState === 1) {
		return (
			<div className="flex flex-col items-center">
				<Spinner className="pt-24 pb-24 lg:py-32" />
			</div>
		)
	}

	if (!cartSkuList.length) {
		return (
			<CommonLayout backHref="/" nextHref="/" cart={cartSkuList}>
				<h2 className="text-2xl font-semibold flex justify-center">{t('cart.empty')}</h2>
			</CommonLayout>
		);
	}

	return (
		<div
			className={`nc-PageAddListing px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
			data-nc-id="PageAddListing"
		>
			<div className="space-y-11">
				<div className="listingSection__wrap">
					<h2 className="text-2xl font-semibold flex justify-center">{t('cart.title')}</h2>
					<span className="text-s text-neutral-500 dark:text-neutral-400 font-normal flex justify-center">
						{`${t('cart.free_ship_tips1')} $${configInfo?.freeShippingOrderAmount || 0} ${t('cart.free_ship_tips2')}`}
					</span>
					<div className="space-y-8">
						<CartSkuItems cartSkuList={cartSkuList} onCartSkuListChange={handleGetCartSkuList} />
						<div className="flex justify-between">
							<div></div>
							<h3 className="text-2xl font-semibold">
								{t('cart.total_amount')}: HKD $ {transcationTotalAmount}
							</h3>
						</div>
					</div>
				</div>
				<div className="flex justify-end space-x-5">
					<ButtonSecondary href={'/'}>{t('common.back')}</ButtonSecondary>
					<ButtonPrimary onClick={onBuyButtonClick} disabled={false}>
						{t('cart.checkout')}
					</ButtonPrimary>
				</div>
			</div>
		</div>
	);
};

export default Cart;
