import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { commonAxiosPostRequest, commonAxiosGetRequest } from "utils/request";
import { getPureArray } from "utils/util";
import { TokenLocalStorageKey } from "utils/constant";

interface IConfigInfo {
	/** 用户协议 */
	userAgreementContent: string;
	/** 隐私协议 */
	privacyPolicyContent: string;
	/** 关于我们 */
	aboutUsContent: string;
	/** 免运费订单金额 */
	freeShippingOrderAmount: number;
};


/** 用户信息 */
interface IUserInfo {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	retailerNo: string;
	phonePrefix: string;
	expressFirstName: string;
	expressLastName: string;
	expressAddress: string;
	expressRegion: string;
	expressPhone: string;
	expressEmail: string;
	expressPostalCode: string;
	userType: 1 | 2;
	couponList: any[];
	vipFlag: 0 | 1;
	vipEffectiveTime: string;
	vipProgressInfo: {
		vipThresholdAmount: number;
		totalConsumptionAmount: number;
		amountToVip: number;
		vipProgress: number;
	};
};

interface AnyInterface {
	auth: boolean;
	setAuth: (boolean: boolean) => void;
	showNavItems: boolean;
	setShowNavItems: (boolean: boolean) => void;
	configInfo: IConfigInfo | null;
	setConfigInfo: (data: IConfigInfo) => void;
	productTypeList: IProductTypeInfo[];
	userInfo: IUserInfo | null | undefined;
}

interface IProductTypeInfo {
	id: string;
	name: string;
	nameHk: string;
	nameEn: string;
	pictureUrl: string;
	linkUrl: string;
};

const defaultState = {
	auth: false,
	setAuth: () => { },
	email: "",
	showNavItems: false,
	setShowNavItems: () => { },
	configInfo: null,
	setConfigInfo: () => { },
	productTypeList: [],
	userInfo: undefined,
};

const AuthContext = React.createContext<AnyInterface>(defaultState);

const AuthProvider: React.FC = ({ children }) => {
	const { t } = useTranslation();
	const [auth, setAuth] = useState<boolean>(false);
	const [showNavItems, setShowNavItems] = useState<boolean>(false);
	const [configInfo, setConfigInfo] = useState<IConfigInfo | null>(null);
	const [productTypeList, setProductTypeList] = useState<IProductTypeInfo[]>([]);
	const [userInfo, setUserInfo] = useState<AnyInterface['userInfo']>(defaultState.userInfo);

	const handleQueryConfigInfo = async () => {
		const [error, response] = await commonAxiosPostRequest<{ config: IConfigInfo }, {}>('/yogurtrain/api/app/common/config', {});
		const config = response?.config || null;
		setConfigInfo(config);
	}

	const handleQueryDataList = async () => {
		const [error, response] = await commonAxiosPostRequest<{ list: IProductTypeInfo[] }, {}>('/yogurtrain/api/app/product/typeList', {
			scene: 1
		});
		const list = getPureArray<IProductTypeInfo>(response?.list);
		setProductTypeList(list);
	}

	// 获取用户信息
	const handleQueryUserInfo = async () => {
		const token = localStorage.getItem(TokenLocalStorageKey);
		if (token) {
			const [error, response] = await commonAxiosPostRequest<{ userInfo: IUserInfo }, {}>('/yogurtrain/api/app/user/userinfo', {});
			setUserInfo(response?.userInfo || null);
			console.log('[handleQueryUserInfo]', response);
		}
	}

	useEffect(() => {
		handleQueryConfigInfo();
		handleQueryDataList();
		handleQueryUserInfo();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
				showNavItems,
				setShowNavItems,
				configInfo,
				setConfigInfo,
				productTypeList,
				userInfo,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
