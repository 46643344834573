import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from 'antd';
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonDialog from "../../components/Dialog/index";
import { commonAxiosPostRequest } from "utils/request";
import { TokenLocalStorageKey } from "utils/constant";

export interface PageLoginProps {
	className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const [formValue, setFormValue] = useState({
		currentStep: 1,
		email: '',
		newPassword: '',
		newPassword2: '',
		accountVerifyCode: '',
	});
	const [loading, setLoading] = useState(false);
	// 弹框展示信息
	const [dialogInfo, setDialogInfo] = useState({
		show: false,
		title: '',
		content: '',
	});

	const onInput = (name: string, e: any) => {
		const value = (e.currentTarget.value || '').trim();
		setFormValue({ ...formValue, [`${name}`]: value });
	}

	const handleSendForgotPasswordEmail = async () => {
		setLoading(true);
		const [error, response] = await commonAxiosPostRequest<{ sendMailSuccess: boolean }, {}>('/yogurtrain/api/app/user/forgot-password', {
			email: formValue.email,
		});
		setLoading(false);
		if (error || !response?.sendMailSuccess) {
			setDialogInfo((prev) => ({ ...prev, show: true, title: t('common.tips'), content: error?.message || 'System Error!' }))
		} else {
			message.success(t('forgot.emailSend'));
			setFormValue({ ...formValue, currentStep: 2 });
		}
	}

	const onSubmit = async () => {
		if (loading) {
			return;
		}
		const { currentStep, email, newPassword, newPassword2, accountVerifyCode } = formValue;
		if (currentStep === 1) {
			if (!email) {
				message.info(t('forgot.emailInput'));
			}
			handleSendForgotPasswordEmail();
			return;
		}
		if (!newPassword || !newPassword2 || newPassword !== newPassword2 || !accountVerifyCode) {
			setDialogInfo((prev) => ({ ...prev, show: true, title: t('common.tips'), content: t('user.formValid') }))
			return;
		}
		setLoading(true);
		const [error, response] = await commonAxiosPostRequest<{ token: string }, {}>('/yogurtrain/api/app/user/reset-password', {
			accountVerifyCode,
			password: newPassword,
		});
		setLoading(false);
		if (error || !response?.token) {
			setDialogInfo((prev) => ({ ...prev, show: true, title: t('common.tips'), content: error?.message || 'System Error!' }))
		} else {
			message.success(t('forgot.success'));
			window.localStorage.setItem(TokenLocalStorageKey, response?.token);
			window.location.href = '/';
		}
	}

	return (
		<div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
			<div className="container mb-24 lg:mb-32">
				<h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
					{t('forgot.title')}
				</h2>
				<div className="max-w-md mx-auto space-y-6">
					<form className="grid grid-cols-1 gap-6" action="#" method="post">
						<label className="block">
							<span className="text-neutral-800 dark:text-neutral-200">
								{t('user.email')}
							</span>
							<Input type="email" placeholder="example@example.com" className="mt-1" onInput={(e) => onInput('email', e)} />
						</label>
						{
							formValue.currentStep === 2
								? (<>
									<label className="block">
										<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
											{t('forgot.code')}
										</span>
										<Input className="mt-1" onInput={(e) => onInput('accountVerifyCode', e)} />
									</label>
									<label className="block">
										<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
											{t('forgot.newPassword')}
										</span>
										<Input type="password" className="mt-1" onInput={(e) => onInput('newPassword', e)} />
									</label>
									<label className="block">
										<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
											{t('forgot.repeatPassword')}
										</span>
										<Input type="password" className="mt-1" onInput={(e) => onInput('newPassword2', e)} />
									</label>
								</>)
								: null
						}
						<ButtonPrimary type="submit" onClick={onSubmit} loading={loading}>
							{formValue.currentStep === 1 ? t('forgot.sendCode') : t('forgot.confirm')}
						</ButtonPrimary>
					</form>
				</div>
			</div>
			<CommonDialog
				{...dialogInfo}
				onClose={() => setDialogInfo((prev) => ({ ...prev, show: false }))}
			/>
		</div>
	);
};

export default PageLogin;
