import { useState, FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { Link, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import { ShoppingCartIcon } from "@heroicons/react/solid";
import { Menu } from 'antd';
import companyLogo from "images/logo.png";
import { AuthContext } from "context/authenticate";
import { getNameByLang } from "utils/util";
import ChangeLang from "shared/ChangeLang/ChangeLang";
import UserDropdown from "shared/UserDropdown/UserDropdown";
import SocialsList from "shared/SocialsList/SocialsList";

export interface MainNavProps {
	isTop: boolean;
}

const MainNav: FC<MainNavProps> = ({ isTop }) => {
	const { t } = useTranslation();
	const { language } = i18n;
	const location = useLocation();
	const NavigationList = [
		{ name: t('nav.index'), link: '/', key: 'index' },
		{ name: t('nav.blogs'), link: '/blogs', key: 'blogs' },
	];
	const { showNavItems, productTypeList, userInfo } = useContext(AuthContext);
	const [navigationList, setNavigationList] = useState(NavigationList);
	const [currentKey, setCurrentKey] = useState('');

	const handleUpdateCurrentKey = () => {
		const pathname = window.location.pathname;
		const current = navigationList.find(n => n.link === pathname)?.key || 'index';
		setCurrentKey(current);
	}

	useEffect(() => {
		const newNavigationList = [
			NavigationList[0],
			...productTypeList.map(item => ({
				name: item[getNameByLang(language)] || item.name || '',
				link: `/category/${item.id}`,
				key: `category${item.id}`
			})),
			...NavigationList.slice(1),
		];
		setNavigationList(newNavigationList);
	}, [productTypeList, language]);

	useEffect(() => {
		handleUpdateCurrentKey();
	}, [location, navigationList]);

	return (
		<div className="nc-MainNav relative z-10 bg-[#FFFFFF] backdrop-filter shadow-sm py-2">
			<div className="sm:container relative flex justify-between items-center space-x-4 xl:space-x-8">
				<div className="flex flex-1 items-center space-x-4 sm:space-x-10 2xl:space-x-14">
					<a href={'/'} className="shrink-0 flex items-center ttnc-logo inline-block text-primary-6000">
						<img className="block w-[60px] h-auto" src={companyLogo} alt="Logo" />
					</a>
					<div className="flex flex-1 items-center min-w-[600px] hidden xl:block">
						<Menu mode="horizontal" selectedKeys={[currentKey]} className="w-full" style={{ background: '#FFFFFF', color: "#e22120" }}>
							{
								navigationList.map(item => {
									return (
										<Menu.Item key={item.key} className="text-[16px]">
											<Link to={item.link}>{item.name}</Link>
										</Menu.Item>
									)
								})
							}
						</Menu>
					</div>
				</div>
				<div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
					<div className="hidden xl:flex items-center space-x-4">
						<SocialsList iconClass="lg:w-[28px]" className="mr-[16px]" />
						<button className="text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
							<Link to="/cart">
								<ShoppingCartIcon className="w-8 h-8" color="#352d2f" />
							</Link>
						</button>
						<ChangeLang />
						{
							userInfo ? (
								<div className="hidden items-center xl:flex">
									<UserDropdown userInfo={userInfo} />
								</div>
							) : (
								<div className="hidden items-center xl:flex">
									<div className="px-1" />
									<ButtonPrimary href="/login">{t('user.login')}</ButtonPrimary>
								</div>
							)
						}
					</div>
					<div className="flex items-center xl:hidden">
						<div className="px-1" />
						<MenuBar navigationList={navigationList} currentKey={currentKey} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainNav;
