import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from 'antd';
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonDialog from "../../components/Dialog/index";
import { commonAxiosPostRequest } from "utils/request";
import { TokenLocalStorageKey } from "utils/constant";

export interface PageLoginProps {
	className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const [formValue, setFormValue] = useState({
		account: '',
		password: '',
	});
	const [loading, setLoading] = useState(false);
	// 弹框展示信息
	const [dialogInfo, setDialogInfo] = useState({
		show: false,
		title: '',
		content: '',
	});
	const userType = new URLSearchParams(window.location.search).get('type') || '1';

	const onInput = (name: string, e: any) => {
		const value = (e.currentTarget.value || '').trim();
		setFormValue({ ...formValue, [`${name}`]: value });
	}

	const onSubmit = async () => {
		if (loading) {
			return;
		}
		if (!formValue.account || !formValue.password) {
			message.info(t('user.formValid'));
			return;
		}
		setLoading(true);
		const [error, response] = await commonAxiosPostRequest<{ token: string }, {}>('/yogurtrain/api/app/user/login', {
			...formValue,
			type: `${userType}` === '2' ? 2 : 1,
		});
		setLoading(false);
		if (error || !response?.token) {
			setDialogInfo((prev) => ({ ...prev, show: true, title: t('common.tips'), content: error?.message || 'System Error!' }))
		} else {
			message.success(t('user.loginSuccess'));
			window.localStorage.setItem(TokenLocalStorageKey, response?.token);
			window.location.href = '/';
		}
	}

	return (
		<div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
			<div className="container mb-24 lg:mb-32">
				<h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
					{t(`user.${userType === '1' ? 'login' : 'userType2'}`)}
				</h2>
				<div className="max-w-md mx-auto space-y-6">
					<form className="grid grid-cols-1 gap-6" action="#" method="post">
						<label className="block">
							<span className="text-neutral-800 dark:text-neutral-200">
								{t(`user.${userType === '1' ? 'email' : 'account'}`)}
							</span>
							<Input type="email" placeholder="example@example.com" className="mt-1" onInput={(e) => onInput('account', e)} />
						</label>
						<label className="block">
							<span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
								{t('user.password')}
								<Link to="/forgot-pass" className="text-sm">
									{t('user.forgotPassword')}
								</Link>
							</span>
							<Input type="password" className="mt-1" onInput={(e) => onInput('password', e)} />
						</label>
						<ButtonPrimary type="submit" onClick={onSubmit} loading={loading}>{t('user.login')}</ButtonPrimary>
					</form>
					<div className="flex items-center justify-between">
						<span className="block text-center text-neutral-700 dark:text-neutral-300">
							{t('user.newUser')} {` `}
							<Link to="/signup">{t('user.createAccount')}</Link>
						</span>
						<span className="block text-center text-neutral-700 dark:text-neutral-300">
							<Link to={`/login?type=${userType === '1' ? 2 : 1}`}>{t(`user.${userType === '1' ? 'userType2' : 'userType1'}`)}</Link>
						</span>
					</div>

				</div>
			</div>
			<CommonDialog
				{...dialogInfo}
				onClose={() => setDialogInfo((prev) => ({ ...prev, show: false }))}
			/>
		</div>
	);
};

export default PageLogin;
