import React, { FC, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { commonAxiosPostRequest } from "utils/request";
import { getPureArray, getCurrentPage } from "utils/util";


export interface CarouselProps {
    className?: string;
};

interface TBannerItem {
    id: string;
    pictureUrl: string;
    linkUrl: string;
};

const TopBannerComponent: FC<CarouselProps> = ({ className = "" }) => {
    const location = useLocation();
    const [bannerList, setBannerList] = useState<TBannerItem[]>([]);

    const handleQueryBannerList = async () => {
        const { page, id } = getCurrentPage();
        const [error, response] = await commonAxiosPostRequest<{ list: TBannerItem[] }, {}>('/yogurtrain/api/app/common/bannerList', {
            pages: page,
            type: 2,
            productTypeId: page === 'category' ? id : null,
        });
        const list = getPureArray<TBannerItem>(response?.list).slice(0, 1);
        setBannerList(list);
    }

    const onBannerItemClick = (item: any) => {
        if (item.linkUrl) {
            window.location.href = item.linkUrl;
        }
    };

    useEffect(() => {
        handleQueryBannerList();
    }, [location]);

    return (
        <div className="w-full">
            {
                bannerList.map(item => (
                    <div key={item.id} className="w-full" onClick={() => onBannerItemClick(item)}>
                        <img src={item.pictureUrl} alt={item.id} className="w-full h-[auto]" />
                    </div>
                ))
            }
        </div>
    )
};

export default TopBannerComponent;