import React, { FC, Fragment, useState, useEffect, useContext } from "react";
import { Tab } from "@headlessui/react";
import fps from "images/fps.png";
import payme from "images/payme_logo.png";
import paymeHsbc from "images/paymeHSBC.png";
import aliPay from "images/aliPay.png";
import weChat from "images/weChat.png";
import master from "images/masterLogo.png";
import visa from "images/visaLogo.png";
import { getPureArray } from "utils/util";

export interface ComponentProps {
    className?: string;
    // onCartSkuListChange?: () => void;
};

const PaymentMethodTab: FC<ComponentProps> = (props) => {
    const { className } = props;

    return (
        <>
            <Tab.List className="flex">
                <Tab as={Fragment}>
                    {({ selected }) => (
                        <div className="paymentMethodWrapper">
                            <div className="paymentMethod grid grid-cols-2 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                <img className="border border-neutral-100 border-solid" style={{
                                    objectFit: "contain",
                                    maxHeight: "200px",
                                    maxWidth: "200px",
                                    height: "100%",
                                    width: "100%",
                                }} src={payme} alt="" />
                                <img className="border border-neutral-100 border-solid" src={fps} alt="" />
                                <img className="border border-neutral-100 border-solid" src={master} alt="" />
                                <img className="border border-neutral-100 border-solid" src={visa} alt="" />
                                <img className="border border-neutral-100 border-solid" src={weChat} alt="" />
                                <img className="border border-neutral-100 border-solid" src={aliPay} alt="" />
                            </div>
                        </div>
                    )}
                </Tab>
            </Tab.List>
        </>
    );
};

export default PaymentMethodTab;