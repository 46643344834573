import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from 'antd';
import NcImage from "shared/NcImage/NcImage";
import wechatPreviewImage from "images/social/wechat-2.png";
import xhsImage from "images/social/xhs.png";
import igImage from "images/social/ig.png";
import facebookImage from "images/social/facebook.png";
import wechatImage from "images/social/wechat.png";

export interface SocialType {
	name: string;
	icon: string;
	href: string;
	type?: string;
}

export interface SocialsListProps {
	className?: string;
	iconClass?: string;
	itemClass?: string;
}


const SocialsList: FC<SocialsListProps> = ({
	className = "",
	iconClass = "",
	itemClass = "block",
}) => {
	const { t } = useTranslation();

	const socials: SocialType[] = [
		// { name: t('footer.wechat'), type: 'wechat', icon: wechatImage, href: `${window.location.origin}/static/media/wechat-2.b316c003.png` },
		{ name: "Facebook", icon: facebookImage, href: "https://www.facebook.com/p/YogurTrain-%E4%B9%B3%E9%85%AA%E7%81%AB%E8%BB%8A-100067474154698/?locale=zh_HK" },
		{ name: "Instagram", icon: igImage, href: "https://www.instagram.com/yogurtrain_hk/" },
		// { name: t('footer.redbook'), icon: xhsImage, href: "https://www.xiaohongshu.com/user/profile/654dd69c00000000020128d2?xhsshare=CopyLink&appuid=63ced77d00000000270297fb&apptime=1719997876&share_id=b89e97df4f164241b0789ec14ed531c0" },
	];
	return (
		<nav
			className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
			data-nc-id="SocialsList"
		>
			{socials.map((item, i) => {
				return <Tooltip
					placement="left"
					color="#fff"
					title={
						item.type === 'wechat' ? <NcImage className="object-cover w-[150px] h-[150px]" src={wechatPreviewImage} /> : null
					}
					// trigger={item.type === 'wechat' ? 'click' : 'hover'}
					trigger={'hover'}
					key={i}
				>
					<a
						key={i}
						className={`${itemClass}`}
						href={item.href}
						target="_blank"
						rel="noopener noreferrer"
						title={item.name}
					>
						<NcImage className={`object-cover w-[20px] lg:w-[42px] h-[auto] ${iconClass || ''}`} src={item.icon} />
					</a>
				</Tooltip>
			})}
		</nav>
	);
};

export default SocialsList;
