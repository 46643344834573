import React, { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { useParams, useHistory } from "react-router-dom";
import Heading2 from "components/Heading/Heading2";
import { AuthContext } from "context/authenticate";
import { getNameByLang } from "utils/util";


export interface CarouselProps {
    className?: string;
};

const ProductTypeComponent: FC<CarouselProps> = ({ className = "" }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { productTypeList } = useContext(AuthContext);
    const { language } = i18n;


    const onItemClick = (item: any) => {
        history.push(`/category/${item.id}`);
    };
    return (
        <div className="max-w-[1280px] mx-auto mt-8 sm:mt-8 lg:mt-16">
            <Heading2 heading={t('product.type_title')} />
            <div className="flex items-center justify-center flex-wrap">
                {
                    productTypeList.map(item => (
                        <div key={item.id} className="flex flex-wrap flex-col items-center mx-0 sm:mx-[32px] mb-4 sm:mb-2 cursor-pointer" onClick={() => onItemClick(item)}>
                            <img src={item.pictureUrl} alt={item.id} className="w-[300px] h-[200px]" />
                            <div className="text-[16px] text-[#000] mt-[16px]">{item[getNameByLang(language)]}</div>
                        </div>
                    ))
                }
            </div>

        </div>
    )
};

export default ProductTypeComponent;