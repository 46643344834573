const hostname = window.location.hostname;
// 接口请求地址
// export const url = ['localhost', '127.0.0.1'].includes(hostname) ? "http://127.0.0.1:7013" : window.location.origin;
export const url = 'https://yogurtrain.test.taotaro.app';

// 接口请求地址
export const RequestBaseUrl = url;

// token缓存key
export const TokenLocalStorageKey = 'YogurtrainMall-Auth-Token';

// 购物车缓存key
export const CartCacheLocalStorageKey = 'YogurtrainMallCartCacheIdentifier';

// 购物表单信息缓存key
export const BuyFormInfoCacheLocalStorageKey = 'YogurtrainBuyFormInfoCacheIdentifier';

export const CountryCodes = [
    { "value": "86", "label": "中国大陆" },
    { "value": "852", "label": "中国香港" },
    { "value": "853", "label": "中国澳门" },
    { "value": "886", "label": "中国台湾" },
    { "value": "1", "label": "美国/加拿大" },
    { "value": "55", "label": "巴西" },
    { "value": "60", "label": "马来西亚" },
    { "value": "61", "label": "澳洲" },
    { "value": "81", "label": "日本" },
    { "value": "82", "label": "韩国" },
    { "value": "65", "label": "新加坡" },
    { "value": "44", "label": "英国" },
    { "value": "33", "label": "法国" },
    { "value": "7", "label": "俄罗斯" },
    { "value": "91", "label": "印度" },
    { "value": "66", "label": "泰国" },
    { "value": "49", "label": "德国" },
    { "value": "39", "label": "意大利" },
    { "value": "34", "label": "西班牙" },
    { "value": "62", "label": "印尼" },
    { "value": "855", "label": "柬埔寨" },
    { "value": "95", "label": "缅甸" },
    { "value": "673", "label": "文莱" },
    { "value": "63", "label": "菲律宾" },
    { "value": "84", "label": "越南" },
    { "value": "856", "label": "老挝" },
    { "value": "48", "label": "波兰" }
];

// 手机区号列表
export const CountryCodesHk = [
    { "value": "86", "label": "中國大陸" },
    { "value": "852", "label": "中國香港" },
    { "value": "853", "label": "中國澳門" },
    { "value": "886", "label": "中國台灣" },
    { "value": "1", "label": "美國/加拿大" },
    { "value": "55", "label": "巴西" },
    { "value": "60", "label": "馬來西亞" },
    { "value": "61", "label": "澳洲" },
    { "value": "81", "label": "日本" },
    { "value": "82", "label": "韓國" },
    { "value": "65", "label": "新加坡" },
    { "value": "44", "label": "英國" },
    { "value": "33", "label": "法國" },
    { "value": "7", "label": "俄國" },
    { "value": "91", "label": "印度" },
    { "value": "66", "label": "泰國" },
    { "value": "49", "label": "德國" },
    { "value": "39", "label": "義大利" },
    { "value": "34", "label": "西班牙" },
    { "value": "62", "label": "印尼" },
    { "value": "855", "label": "柬埔寨" },
    { "value": "95", "label": "緬甸" },
    { "value": "673", "label": "汶萊" },
    { "value": "63", "label": "菲律賓" },
    { "value": "84", "label": "越南" },
    { "value": "856", "label": "寮國" },
    { "value": "48", "label": "波蘭" }
];

export const CountryCodesEn = [
    { "value": "86", "label": "Mainland China" },
    { "value": "852", "label": "Hong Kong, China" },
    { "value": "853", "label": "Macau, China" },
    { "value": "886", "label": "Taiwan, China" },
    { "value": "1", "label": "United States/Canada" },
    { "value": "55", "label": "Brazil" },
    { "value": "60", "label": "Malaysia" },
    { "value": "61", "label": "Australia" },
    { "value": "81", "label": "Japan" },
    { "value": "82", "label": "South Korea" },
    { "value": "65", "label": "Singapore" },
    { "value": "44", "label": "UK" },
    { "value": "33", "label": "France" },
    { "value": "7", "label": "Russia" },
    { "value": "91", "label": "India" },
    { "value": "66", "label": "Thailand" },
    { "value": "49", "label": "Germany" },
    { "value": "39", "label": "Italy" },
    { "value": "34", "label": "Spain" },
    { "value": "62", "label": "Indonesia" },
    { "value": "855", "label": "Cambodia" },
    { "value": "95", "label": "Myanmar" },
    { "value": "673", "label": "Brunei" },
    { "value": "63", "label": "Philippines" },
    { "value": "84", "label": "Vietnam" },
    { "value": "856", "label": "Laos" },
    { "value": "48", "label": "Poland" }
];