import { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { message, Modal } from 'antd';
import Select from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import VipRender from "./components/VipRender";
import ShippingAddress from "../CustomerInfoPage/components/ShippingAddress";
import Input from "shared/Input/Input";
import { AuthContext } from "context/authenticate";
import { commonAxiosPostRequest } from "utils/request";
import { TokenLocalStorageKey, CountryCodes, CountryCodesHk, CountryCodesEn } from "utils/constant";

export interface AccountPageProps {
	className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
	const { language } = i18n;
	const { userInfo } = useContext(AuthContext);
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [formValue, setFormValue] = useState<any>({
		firstName: '',
		lastName: '',
		retailerNo: '',
		phonePrefix: '',
		phone: '',
		email: '',
		expressFirstName: '',
		expressLastName: '',
		regionInfo: {
			province: '',
			city: '',
			address: '',
		},
		expressPhone: '',
		expressEmail: '',
		expressPostalCode: '',
		password: '',
	});

	const onInput = (name: string, e: any) => {
		const value = (e.currentTarget.value || '').trim();
		setFormValue({ ...formValue, [`${name}`]: value });
	}

	// Select选择change
	const onSelectChange = (e: any) => {
		const { name, value } = e.target;
		setFormValue({ ...formValue, [`${name}`]: value });
	};

	const onSubmit = async () => {
		const { firstName, lastName } = formValue;
		if (loading) {
			return;
		}
		if (!firstName || !lastName) {
			message.info(t('user.formValid'));
			return;
		}
		const { province, city } = formValue?.regionInfo || {};
		setLoading(true);
		const [error, response] = await commonAxiosPostRequest<{ isPassswordChanged: boolean }, {}>('/yogurtrain/api/app/user/update', {
			...formValue,
			expressAddress: formValue?.regionInfo?.address || '',
			expressRegion: (province && city) ? `${province}__${city}` : '',
		});
		setLoading(false);
		if (error || !response) {
			message.error(error?.message || 'System Error!');
		} else {
			if (response.isPassswordChanged) {
				Modal.info({
					title: t('user.changeSuccessAndPasswordChange'),
					okText: t('coupon.login'),
					onOk: () => {
						window.localStorage.setItem(TokenLocalStorageKey, '');
						window.location.href = '/login';
					}
				});
			} else {
				message.success(t('user.changeSuccess'));
			}
		}
	}

	// 修改收货地址change
	const onRegionInfoChange = (newRegionInfo: any, type: string) => {
		setFormValue((prevState: any) => ({ ...prevState, regionInfo: newRegionInfo }));
	}

	useEffect(() => {
		if (userInfo) {
			const [provinceCode, cityCode] = (userInfo?.expressRegion || '').split('__');
			setFormValue({
				...formValue,
				retailerNo: userInfo.retailerNo || '',
				userType: userInfo.userType,
				firstName: userInfo.firstName || '',
				lastName: userInfo.lastName || '',
				phone: userInfo.phone || '',
				phonePrefix: userInfo.phonePrefix || '',
				email: userInfo.email || '',
				expressFirstName: userInfo.expressFirstName || '',
				expressLastName: userInfo.expressLastName || '',
				expressPhone: userInfo.expressPhone || '',
				expressEmail: userInfo.expressEmail || '',
				expressPostalCode: userInfo.expressPostalCode || '',
				regionInfo: {
					province: provinceCode || "",
					city: cityCode || "",
					address: userInfo?.expressAddress || "",
				},
			});
		} else if (userInfo === null) {
			message.error(t('user.validToken'));
			setTimeout(() => {
				window.localStorage.setItem(TokenLocalStorageKey, '');
				window.location.href = '/login';
			}, 1000);
		}
	}, [userInfo]);

	const countryCodes = language === 'zh-HK' ? CountryCodesHk : (language === 'en' ? CountryCodesEn : CountryCodes);

	return (
		<div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
			<div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">
				<div className="space-y-6 sm:space-y-8">
					<VipRender></VipRender>
					<h2 className="text-3xl font-semibold">{t('user.info')}</h2>
					<div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
					<div className="flex flex-col md:flex-row">
						<div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
							<h4 className="text-xl font-semibold">{t('user.detail')}</h4>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.firstName')}
									<span className="text-[red]">*</span>
								</div>
								<Input placeholder="" className="mt-1" value={formValue.firstName} onInput={(e) => onInput('firstName', e)} />
							</label>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.lastName')}
									<span className="text-[red]">*</span>
								</div>
								<Input placeholder="" className="mt-1" value={formValue.lastName} onInput={(e) => onInput('lastName', e)} />
							</label>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.phone')}
								</div>
								<div className="flex items-center">
									<Select className="w-auto" name="phonePrefix" onChange={onSelectChange} value={formValue.phonePrefix}>
										<option value="" disabled>{t('common.select_placeholder')}</option>
										{countryCodes.map((item: any) => {
											return (<option value={item.value} key={item.label}>{item.label}</option>);
										})}
									</Select>
									<Input placeholder="" value={formValue.phone} className="mt-1" onInput={(e) => onInput('phone', e)} />
								</div>
							</label>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.email')}
									<span className="text-[red]">*</span>
								</div>
								<Input type="email" disabled={true} placeholder="example@example.com" value={formValue.email} className="mt-1 cursor-not-allowed opacity-30" onInput={(e) => onInput('email', e)} />
							</label>
							{
								formValue.userType === 2 ? (<label className="block">
									<div className="text-neutral-800 dark:text-neutral-200">
										{t('user.account')}
									</div>
									<Input value={formValue.retailerNo} disabled={true} className="mt-1 cursor-not-allowed opacity-30" />
								</label>) : null
							}
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.password')}
									<span className="text-[gray] text-[14px] ml-[14px]">({t('user.passwordChange')})</span>
								</div>
								<Input type="password" autoComplete="new-password" value={formValue.password} className="mt-1" onInput={(e) => onInput('password', e)} />
							</label>
							<div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
							<h4 className="text-xl font-semibold">{t('user.address')}</h4>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.firstName')}
									<span className="text-[red]">*</span>
								</div>
								<Input placeholder="" className="mt-1" value={formValue.expressFirstName} onInput={(e) => onInput('expressFirstName', e)} />
							</label>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.lastName')}
									<span className="text-[red]">*</span>
								</div>
								<Input placeholder="" className="mt-1" value={formValue.expressLastName} onInput={(e) => onInput('expressLastName', e)} />
							</label>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('checkout.address')}
									<span className="text-[red]">*</span>
								</div>
								<ShippingAddress regionInfo={formValue.regionInfo} onChange={onRegionInfoChange}></ShippingAddress>
							</label>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.phone')}
								</div>
								<Input placeholder="" className="mt-1" value={formValue.expressPhone} onInput={(e) => onInput('expressPhone', e)} />
							</label>
							<label className="block">
								<div className="text-neutral-800 dark:text-neutral-200">
									{t('user.email')}
								</div>
								<Input type="email" placeholder="example@example.com" value={formValue.expressEmail} className="mt-1" onInput={(e) => onInput('expressEmail', e)} />
							</label>
							<ButtonPrimary type="submit" onClick={onSubmit} loading={loading}>{t('user.submit')}</ButtonPrimary>
						</div>
					</div>

					<h2 className="text-3xl font-semibold">{t('user.coupon')}</h2>
					<div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
					{
						(userInfo?.couponList || []).map(item => {
							return (
								<div className="max-w-sm bg-white rounded-lg shadow-lg border border-dashed border-gray-300 p-6 relative">
									<div className="text-center">
										<h2 className="text-2xl font-bold text-red-600">{item.couponName}</h2>
										<p className="text-gray-600">{item.couponDesc || ''}</p>
									</div>
									<div className="mt-4">
										<div className="mt-4 flex justify-between items-center">
											<span className="text-gray-500 text-xs">{t('user.expires')}: {item.couponValidityEndTime}</span>
											<span className="bg-red-600 text-white px-3 py-1 rounded-full text-xs">{t('user.code')}: {item.couponCode}</span>
										</div>
									</div>
								</div>
							);
						})
					}
				</div>
			</div>
		</div >
	);
};

export default AccountPage;
