import { FC, useState } from "react";
import i18n from 'i18next';
import { Link } from "react-router-dom";
import Input from "shared/Input/Input";
import { useTranslation } from "react-i18next";
import Select from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonDialog from "../../components/Dialog/index";
import { message } from 'antd';
import { commonAxiosPostRequest } from "utils/request";
import { TokenLocalStorageKey, CountryCodes, CountryCodesHk, CountryCodesEn } from "utils/constant";

export interface PageSignUpProps {
	className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
	const { language } = i18n;
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [formValue, setFormValue] = useState({
		firstName: '',
		lastName: '',
		phonePrefix: '',
		phone: '',
		email: '',
		password: '',
	});
	// 弹框展示信息
	const [dialogInfo, setDialogInfo] = useState({
		show: false,
		title: '',
		content: '',
	});

	const onInput = (name: string, e: any) => {
		const value = (e.currentTarget.value || '').trim();
		setFormValue({ ...formValue, [`${name}`]: value });
	}

	// Select选择change
	const onSelectChange = (e: any) => {
		const { name, value } = e.target;
		setFormValue({ ...formValue, [`${name}`]: value });
	};

	const onSubmit = async () => {
		console.log('[formValue]', formValue);
		if (loading) {
			return;
		}
		if (!formValue.firstName || !formValue.lastName || !formValue.email || !formValue.password) {
			setDialogInfo((prev) => ({ ...prev, show: true, title: t('common.tips'), content: t('user.formValid') }))
			return;
		}
		setLoading(true);
		const [error, response] = await commonAxiosPostRequest<{ token: string }, {}>('/yogurtrain/api/app/user/register', {
			...formValue
		});
		if (error || !response?.token) {
			setLoading(false);
			setDialogInfo((prev) => ({ ...prev, show: true, title: t('common.tips'), content: error?.message || 'System Error!' }))
		} else {
			message.success(t('user.signUpSuccess'));
			window.localStorage.setItem(TokenLocalStorageKey, response?.token);
			setTimeout(() => {
				window.location.href = '/';
			}, 500);
		}
	}

	const countryCodes = language === 'zh-HK' ? CountryCodesHk : (language === 'en' ? CountryCodesEn : CountryCodes);

	return (
		<div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
			<div className="container mb-24 lg:mb-32">
				<h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
					{t('user.signUp')}
				</h2>
				<div className="max-w-md mx-auto space-y-6 ">
					<div className="grid grid-cols-1 gap-6">
						<label className="block">
							<div className="text-neutral-800 dark:text-neutral-200">
								{t('user.firstName')}
								<span className="text-[red]">*</span>
							</div>
							<Input placeholder="" className="mt-1" onInput={(e) => onInput('firstName', e)} />
						</label>
						<label className="block">
							<div className="text-neutral-800 dark:text-neutral-200">
								{t('user.lastName')}
								<span className="text-[red]">*</span>
							</div>
							<Input placeholder="" className="mt-1" onInput={(e) => onInput('lastName', e)} />
						</label>
						<label className="block">
							<div className="text-neutral-800 dark:text-neutral-200">
								{t('user.phone')}
							</div>
							<div className="flex items-center">
								<Select className="w-auto" name="phonePrefix" onChange={onSelectChange} value={formValue.phonePrefix}>
									<option value="" disabled selected>{t('common.select_placeholder')}</option>
									{countryCodes.map((item: any) => {
										return (<option value={item.value} key={item.value}>{item.label}</option>);
									})}
								</Select>
								<Input placeholder="" className="mt-1" onInput={(e) => onInput('phone', e)} />
							</div>
						</label>
						<label className="block">
							<div className="text-neutral-800 dark:text-neutral-200">
								{t('user.email')}
								<span className="text-[red]">*</span>
							</div>
							<Input type="email" placeholder="example@example.com" className="mt-1" onInput={(e) => onInput('email', e)} />
						</label>
						<label className="block">
							<div className="text-neutral-800 dark:text-neutral-200">
								{t('user.password')}
								<span className="text-[red]">*</span>
							</div>
							<Input type="password" className="mt-1" onInput={(e) => onInput('password', e)} />
						</label>
						<ButtonPrimary type="submit" onClick={onSubmit} loading={loading}>{t('user.signUp')}</ButtonPrimary>
					</div>
					<span className="block text-center text-neutral-700 dark:text-neutral-300">
						{t('user.accountHave')} {` `}
						<Link to="/login">{t('user.login')}</Link>
					</span>
				</div>
			</div>
			<CommonDialog
				{...dialogInfo}
				onClose={() => setDialogInfo((prev) => ({ ...prev, show: false }))}
			/>
		</div>
	);
};

export default PageSignUp;
