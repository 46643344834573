import React, { FC, Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import paymeHsbc from "images/paymeHSBC.png";
import Label from "components/Label/Label";
import QrCodeGen from "../QrCodeGen/QrCodeGen";
import CommonDialog from "../../../components/Dialog/index";
import { commonAxiosPostRequest } from "utils/request";
import { getPureArray, safeParse } from "utils/util";

export interface ComponentProps {
    className?: string;
    qrLink: string;
    orderId: string;
};

type TPaymentResult = {
    id: number;
    /** 0未支付、1支付成功、2支付失败 */
    paymentStatus: 0 | 1 | 2;
    statusDescription: string;
    customId: string;
    paymentMethod: string;
    totalPrice: number;
    expressFee: number;
};

const PaymeQrCodeRender: FC<ComponentProps> = (props) => {
    const history = useHistory();
    const { className, qrLink, orderId } = props;
    const [loading, setLoading] = useState(true);
    // 弹框展示信息
    const [dialogInfo, setDialogInfo] = useState({
        show: false,
        title: '',
        content: '',
    });
    // 轮训请求支付结果定时器
    const [paymentPollingTimer, setPaymentPollingTimer] = useState<any>(-1);

    // 轮训请求支付结果
    const handleQueryPaymentResult = async () => {
        clearTimeout(paymentPollingTimer);
        setLoading(true);
        const [error, response] = await commonAxiosPostRequest<TPaymentResult, {}>('/yogurtrain/api/app/product/payment-result', { orderId });
        setLoading(false);
        const { paymentStatus, statusDescription = '' } = response || { paymentStatus: -1 };
        if (![0, 1, 2].includes(paymentStatus) || paymentStatus === 0) {
            setPaymentPollingTimer(setTimeout(() => {
                handleQueryPaymentResult();
            }, 3000));
        } else if (paymentStatus === 1) {
            history.push(`/payment-result?orderId=${response?.customId}`);
        } else {
            setDialogInfo((prev) => ({ ...prev, show: true, title: '提示', content: statusDescription || '查詢失敗，請稍後重試' }))
        }
    };

    useEffect(() => {
        let timer = setTimeout(() => {
            handleQueryPaymentResult();
        }, 5000);
        return () => {
            clearTimeout(timer);
            clearTimeout(paymentPollingTimer);
        }
    }, []);

    return (
        <>
            <div className="space-y-1">
                <img
                    style={{
                        objectFit: "contain",
                        maxHeight: "250px",
                        maxWidth: "250px",
                        height: "100%",
                        width: "100%",
                    }}
                    src={paymeHsbc}
                    alt=""
                />
                <Label>{"請用 PayMe 掃描此 PayCode"}</Label>
                <QrCodeGen url={qrLink} />
                <Label>{"在交易完成前請不要關閉此頁面"}</Label>
                <br />
                <Label>{"如何用 PayMe 付款？"}</Label>
                <br />
                <Label>{"1. 打開 PayMe 應用程式"}</Label>
                <br />
                <Label>{"2. 掃描 PayCode 以授權付款"}</Label>
                <br />
                <Label>{"3. 在 PayMe 完成交易後，請留在此頁面等待確認"}</Label>
            </div>
            <CommonDialog
                {...dialogInfo}
                onClose={() => setDialogInfo((prev) => ({ ...prev, show: false }))}
            />
        </>
    );
};

export default PaymeQrCodeRender;