import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "components/Spinner/Spinner";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import OneButtonCommonLayout from "containers/PageAddListing/OneButtonCommonLayout";
import CommonDialog from "../../components/Dialog/index";
import { commonAxiosPostRequest } from "utils/request";
import { CartCacheLocalStorageKey, TokenLocalStorageKey } from "utils/constant";
import { getPureArray, safeParse } from "utils/util";

export interface PaymentConfirmProps {
	className?: string;
}

type TPaymentResult = {
	id: number;
	/** 0未支付、1支付成功、2支付失败 */
	paymentStatus: 0 | 1 | 2;
	statusDescription: string;
	customId: string;
	paymentMethod: string;
	totalPrice: number;
	expressFee: number;
};

const PaymentConfirmPage: FC<PaymentConfirmProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	// 支付结果
	const [paymentResult, setPaymentResult] = useState<null | TPaymentResult>(null);
	const [autoRegisterUserInfo, setAutoRegisterUserInfo] = useState<any>(null);
	// 弹框展示信息
	const [dialogInfo, setDialogInfo] = useState({
		show: false,
		title: '',
		content: '',
	});
	// 轮训请求支付结果定时器
	const [paymentPollingTimer, setPaymentPollingTimer] = useState<any>(-1);

	// 轮训请求支付结果
	const handleQueryPaymentResult = async () => {
		clearTimeout(paymentPollingTimer);
		setLoading(true);
		const query = new URLSearchParams(window.location.search);
		const orderId = query.get('orderId');
		const [error, response] = await commonAxiosPostRequest<TPaymentResult, {}>('/yogurtrain/api/app/product/payment-result', { orderId });
		const { paymentStatus, statusDescription = '' } = response || { paymentStatus: -1 };
		if (![0, 1, 2].includes(paymentStatus) || paymentStatus === 0) {
			setPaymentPollingTimer(setTimeout(() => {
				handleQueryPaymentResult();
			}, 3000));
		} else if (paymentStatus === 1) {
			setPaymentResult(response);
			setLoading(false);
			window.localStorage.setItem(CartCacheLocalStorageKey, '');
		} else {
			setLoading(false);
			setDialogInfo((prev) => ({
				...prev,
				show: true,
				title: t('common.tips'),
				content: statusDescription || '查詢失敗，請稍後重試'
			}));
		}
	};

	// 自动注册新用户时的处理
	const handleAutoRegisterUserOnPaymentComplete = () => {
		const query = new URLSearchParams(window.location.search);
		const email = query.get('email');
		const password = query.get('password');
		if (email) {
			setAutoRegisterUserInfo({ email, password });
		}
	}

	useEffect(() => {
		handleQueryPaymentResult();
		handleAutoRegisterUserOnPaymentComplete();
	}, []);

	return (
		<main className="container mt-11 mb-24 flex justify-center lg:flex-row flex-col">

			{
				(loading === false && autoRegisterUserInfo) ? (
					<div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full text-center">
						<h1 className="text-2xl font-semibold text-gray-800 mb-6">{t('user.autoCreateAccountTitle')}</h1>
						<p className="text-gray-700 mb-4">{t('user.autoCreateAccountDesc')}</p>

						<div className="mb-6">
							<label className="block text-sm font-medium text-gray-700 mb-2">{t('user.email')}</label>
							<div className="border rounded-lg px-4 py-2 bg-gray-50 text-gray-900">
								{autoRegisterUserInfo.email}
							</div>
						</div>

						<div className="mb-6">
							<label className="block text-sm font-medium text-gray-700 mb-2">{t('user.password')}</label>
							<div className="border rounded-lg px-4 py-2 bg-gray-50 text-gray-900">
								{autoRegisterUserInfo.password}
							</div>
						</div>

						<p className="text-sm text-gray-600 mb-8">{t('user.autoCreateAccountLogin')}</p>

						<ButtonPrimary href="/login">{t('user.login')}</ButtonPrimary>
					</div>
				) : null
			}

			{loading === true
				? <Spinner className="pt-24 pb-24 lg:py-32" />
				: (
					paymentResult
						? <OneButtonCommonLayout backtHref="/">
							<h2 className="text-3xl lg:text-4xl font-semibold">{t('checkout.thanks_title')}</h2>
							<div className="border-b border-neutral-200 dark:border-neutral-700"></div>
							<div className="text-[20px] lg:text-2xl font-semibold whitespace-normal break-all">
								{`${t('checkout.thanks_desc1')}${paymentResult.customId}${t('checkout.thanks_desc2')}`}
							</div>
						</OneButtonCommonLayout>
						: <OneButtonCommonLayout backtHref="/">
							<h2 className="text-3xl lg:text-4xl font-semibold">{t('checkout.error_title')}</h2>
							<div className="border-b border-neutral-200 dark:border-neutral-700"></div>
							<h3 className="text-2xl font-semibold">{t('checkout.error_desc')}</h3>
						</OneButtonCommonLayout>
				)
			}
			<CommonDialog
				{...dialogInfo}
				onClose={() => setDialogInfo((prev) => ({ ...prev, show: false }))}
			/>
		</main>
	);
};

export default PaymentConfirmPage;
