import { useEffect, useState } from "react";
import Spinner from "components/Spinner/Spinner";
import i18n from 'i18next';
import { commonAxiosPostRequest } from "utils/request";
import { getPureArray, getKeyByLang } from "utils/util";

const BlogDetail = () => {
	const { language } = i18n;
	const [blogPost, setBlogPost] = useState<any>(null);

	const handleQueryDataList = async () => {
		const query = new URLSearchParams(window.location.search);
		const [error, response] = await commonAxiosPostRequest<{ list: any[] }, {}>('/yogurtrain/api/app/common/blogs', {
			pageNo: 1,
			pageSize: 1,
			id: query.get('id') || '',
		});
		setBlogPost(getPureArray(response?.list)[0]);
	}

	useEffect(() => {
		handleQueryDataList();
	}, []);

	if (!blogPost) {
		return (
			<div className="flex flex-col items-center">
				<Spinner className="pt-24 pb-24 lg:py-32" />
			</div>
		)
	}

	const content = blogPost[getKeyByLang(language, 'content')] || blogPost.content || '';
	const title = blogPost[getKeyByLang(language, 'title')] || blogPost.title || '';

	return (
		<div className="container mx-auto px-4 py-8">
			<article className="max-w-4xl mx-auto">
				<header className="mb-8">
					<h1 className="text-4xl font-bold text-gray-900 mb-4">{title}</h1>
					<div className="text-sm text-gray-500">
						<span>{blogPost.createTime}</span>
					</div>
				</header>

				<div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: content }} />
			</article>
		</div>
	);
};

export default BlogDetail;
